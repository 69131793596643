/**
 * Renders non-reversal line items that are not listed in the
 * `LINE_ITEMS` array in util/types.js
 *
 * The line items are rendered so that the line item code is formatted to human
 * readable form and the line total is printed as price.
 *
 * If you require another kind of presentation for your line items, add them to
 * the `LINE_ITEMS` array in util/types.js and create a specific line item
 * component for them that can be used in the `OrderBreakdown` component.
 */
import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { humanizeLineItemCode } from '../../util/data';
import { LINE_ITEMS, propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';

const LineItemUnknownItemsMaybe = props => {
  const { lineItems, isProvider, intl, transaction } = props;

  const { eachCartItem , cartItems } = transaction?.attributes?.protectedData || {};
  const { customisationTitle, customizationText , authorId, shippingLabel  } = (typeof eachCartItem == "object") ? eachCartItem : {};

 
  const addShippingLabel = shippingLabel ? <div className={css.lineItem}>
    <span className={css.itemLabel}>
      <FormattedMessage id="OrderBreakdown.shippingFeeLabel" />
    </span>
    <span className={css.limitText}>
      {["Pickup"].includes(shippingLabel) ? <FormattedMessage id="OrderBreakdown.shippingFeeLabelPickup" />
        : ["Rural Flat Rate "].includes(shippingLabel) ? <FormattedMessage id="OrderBreakdown.shippingFeeLabelRural" />
          : <FormattedMessage id="OrderBreakdown.shippingFeeLabelDelivery" />
      }
    </span>
  </div>
    : null;

  const customisationTitleMaybe = customisationTitle ? <><div className={css.lineItem}>
    <span className={css.itemLabel}>
      <FormattedMessage id="OrderBreakdown.customisationTitleMaybe" />
    </span>
    <span className={css.limitText}>
      {customisationTitle}
    </span>
  </div>
    <div className={css.lineItem} >
      {customizationText && typeof customizationText == "string" ? <input className={css.inputText} type="textarea" value={customizationText} /> : null}
    </div>
  </> : null;

  // resolve unknown non-reversal line items
  const allItems = lineItems.filter(item => LINE_ITEMS.indexOf(item.code) === -1 && !item.reversal);

  const items = isProvider
    ? allItems.filter(item => item.includeFor.includes('provider'))
    : allItems.filter(item => item.includeFor.includes('customer'));

  return (
    <React.Fragment>
      {Array.isArray(items) ? items.map((item, i) => {
        const quantity = item.quantity;
        const isDeliveryFee = item.code == "line-item/delivery-fee";
        const isCustomization = item.code == "line-item/customisation-fee";

        const label =
          quantity && quantity > 1
            ? `${humanizeLineItemCode(item.code)} x ${quantity}`
            : humanizeLineItemCode(item.code);

        const formattedTotal = formatMoney(intl, item.lineTotal);
        return (<>
          <div key={`${i}-item.code`} className={css.lineItem}>
            <span className={css.itemLabel}>{label}</span>
            <span className={css.itemValue}>{formattedTotal}</span>
          </div>
          {isCustomization ? customisationTitleMaybe : null}
          {/* {isDeliveryFee ?  addShippingLabel : null } */}
        </>
        );
      }) : null}
      {shippingLabel ? addShippingLabel : null}
    </React.Fragment>
  );
};

LineItemUnknownItemsMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemUnknownItemsMaybe;
